import PropTypes from 'prop-types'
import {Helmet} from 'gatsby-plugin-react-i18next'


const Seo = ({title, meta, seo}) => {
  return (
    <Helmet
        async
        titleTemplate="%s"
        title={title || ''}
        meta={[
          {
            name: 'description',
            content: seo?.metaDescription,
          },
          {
            property: 'og:title',
            content: seo?.metaTitle || '',
          },
          {
            property: 'og:description',
            content: seo?.metaDescription,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:image',
            content: seo?.image?.localFile.publicURL || '',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:creator',
            content: '',
          },
          {
            name: 'twitter:title',
            content: title,
          },
          {
            name: 'twitter:description',
            content: seo?.metaDescription,
          },
          {
            name: 'twitter:image',
            content: seo?.image?.localFile.publicURL || '',
          },
        ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  meta: [],
}

Seo.propTypes = {
  title: PropTypes.string,
  meta: PropTypes.array,
  seo: PropTypes.object,
}

export default Seo
