import PropTypes from 'prop-types'
import {For} from 'babel-plugin-jsx-control-statements'
import {slice, isNumber, sortBy} from 'lodash'
import {Link, useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {Section, ContentContainer, PackageCard, Flex, SSRSuspense} from 'uikit'
import {useState} from 'react'
import Slider from '../../../uikit/src/components/Slider/lazy'
import Image from '../Image'


const Packages = ({to, paymentPage, packages, activePackage, setActivePackage}) => {
  const [control, setContrtol] = useState(null)
  const {language: currentLang} = useI18next()
  const {t} = useTranslation()
  const sortedPackages = sortBy(paymentPage?.packages || packages, 'order')
  const sliderPackages = sortedPackages
  const handlePackageClick = (id) => () => {
    if (setActivePackage) {
      setActivePackage(id)
    }
  }

  const fallback = (
    <Flex responsive distribute>
      <For each="card" of={slice(sliderPackages, 0, 3)}>
        <PackageCard
            title={card?.title}
            image={
              <Image
                  image={card?.image?.localFile}
                  alt={card?.image?.alternativeText}
              />}
            price={card?.price && card?.price}
            key={card?.strapi_id}
        >
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{__html: card?.features.data.childMarkdownRemark.html}} />
        </PackageCard>
      </For>
    </Flex>
  )

  return (
    <ContentContainer>
      <Section>
        <SSRSuspense fallback={fallback}>
          <Slider
              control={control}
              prevArrowTitle={t('packages.arrow.left')}
              nextArrowTitle={t('packages.arrow.right')}
              slidesPerView={1.1}
              hideArrows={sliderPackages.length < 4}
              breakpoints={{
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              onSwiper={setContrtol}
          >
            <For each="slide" of={sliderPackages}>
              <PackageCard
                  key={slide.strapi_id}
                  component={to && Link}
                  to={to}
                  language={to && currentLang}
                  title={slide?.title}
                  highlightedText={slide?.highlight}
                  image={
                    <Image
                        image={slide?.image?.localFile}
                        alt={slide?.image?.alternativeText}
                    />}
                  price={`${slide?.price}${slide?.currency}`}
                  active={isNumber(activePackage) && activePackage === slide.strapi_id}
                  onClick={!to ? handlePackageClick(slide.strapi_id) : undefined}
              >
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{__html: slide?.features?.data?.childMarkdownRemark?.html}} />
              </PackageCard>
            </For>
          </Slider>
        </SSRSuspense>
      </Section>
    </ContentContainer>
  )
}

Packages.propTypes = {
  to: PropTypes.string,
  activePackage: PropTypes.number,
  setActivePackage: PropTypes.func,
  packages: PropTypes.array,
  paymentPage: PropTypes.object,
}

export default Packages
