import PropTypes from 'prop-types'
import {Link} from 'gatsby-plugin-react-i18next'
import {isExternal as isDefaultExternal} from '../utils/isExternal'


const chooseProps = (external, to) => {
  if (external) {
    return {
      href: to,
      rel: 'noopener noreferrer',
      target: '_blank',
    }
  }

  return {
    to,
  }
}

const RegularLink = (props) => <a {...props} />

const BaseLink = ({to, isExternal, children, ...props}) => {
  const external = isExternal || isDefaultExternal(to)
  const Component = external ? RegularLink : Link

  return (
    <Component {...props} {...chooseProps(external, to)}>
      {children}
    </Component>
  )
}

BaseLink.propTypes = {
  to: PropTypes.string,
  isExternal: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default BaseLink
