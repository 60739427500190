import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {If, For} from 'babel-plugin-jsx-control-statements'
import {
  Navbar, Footer, InstagramIcon, FacebookIcon, LangSwitcher, flags, Button, CookieBar, useIsClient, Text,
} from 'uikit'
import {filter, isEmpty} from 'lodash-es'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {INITIAL_CONSENT, useConsent} from '../hooks/useConsent'
import Seo from './Seo'
import BaseLink from './BaseLink'
import Image from './Image'


const icons = {
  instagram: <InstagramIcon color="text2" size="md" />,
  facebook: <FacebookIcon color="text2" size="md" />,
}


const AppShell = ({title, brand, seo, children}) => {
  const {language: currentLang, languages, originalPath} = useI18next()
  const {t} = useTranslation()
  const isClient = useIsClient()
  const otherLanguages = filter(languages, (language) => language !== currentLang)

  const [visibleOptions, setVisibleOptions] = useState(false)
  const {preferences, consent} = useConsent()
  const [visible, setVisible] = useState(!preferences)
  const [selectedCookies, setSelected] = useState()

  useEffect(() => {
    setSelected(preferences || INITIAL_CONSENT)
  }, [preferences])

  const handleSelectedChange = (e) => {
    const {name, checked} = e.target

    setSelected((prevState) => ({...prevState, [name]: checked}))
  }

  const handleOpenOptions = () => {
    setVisibleOptions(true)
  }

  const handleCookiesEdit = () => {
    setVisible(true)
  }

  const handleAllow = () => {
    consent({
      analyticalCookies: true,
      marketingCookies: true,
    })
    setVisible(false)
    setVisibleOptions(false)
  }

  const handleAllowSelected = () => {
    consent(selectedCookies)
    setVisible(false)
    setVisibleOptions(false)
  }

  const handleReject = () => {
    consent({analyticalCookies: false, marketingCookies: false})
    setVisible(false)
    setVisibleOptions(false)
  }

  return (
    <>
      <Seo title={title} seo={seo} facebookDomainVerification={brand?.facebookDomainVerification} />
      <Navbar>
        <LangSwitcher>
          <BaseLink key={currentLang} to={originalPath} language={currentLang}>
            <LangSwitcher.Flag
                isActive
                src={flags[currentLang]}
                alt={currentLang}
            />
          </BaseLink>
          <For each="lang" of={otherLanguages}>
            <BaseLink key={lang} to={originalPath} language={lang}>
              <LangSwitcher.Flag
                  src={flags[lang]}
                  alt={lang}
              />
            </BaseLink>
          </For>
        </LangSwitcher>
      </Navbar>
      {children}
      <Footer>
        <Footer.Logo>
          <Image image={brand.footerLogo.localFile} alt={brand.footerLogo.alternativeText} loading="lazy" />
          <Text color="text2">{brand?.footerText}</Text>
        </Footer.Logo>
        <Footer.Socials>
          <If condition={!isEmpty(brand?.email)}>
            <Footer.Email>
              <a href={`mailto:${brand?.email}`}>{brand?.email}</a>
            </Footer.Email>
          </If>
          <If condition={!isEmpty(brand?.socials)}>
            <For each="social" of={brand?.socials} index="idx">
              <Footer.Social key={idx} href={social.link} title={social.title}>
                {icons[social.icon]}
              </Footer.Social>
            </For>
          </If>
        </Footer.Socials>
        <Footer.Cookies>
          <If condition={brand?.FooterLinks}>
            <For each="link" of={brand?.FooterLinks}>
              <li>
                <a href={link.link} target="_blank" rel="noreferrer noopener">{link.text}</a>
              </li>
            </For>
          </If>
          <li>
            <a href={brand?.gdprLink} target="_blank" rel="noreferrer noopener">{brand?.gdprLinkText}</a>
          </li>
          <li>
            <Button onClick={handleCookiesEdit} variant="plain">{t('cookie_settings')}</Button>
          </li>
        </Footer.Cookies>
      </Footer>
      <If condition={visible && isClient}>
        <CookieBar
            visible={visible}
            selectedCookies={selectedCookies}
            visibleOptions={visibleOptions}
            onAllow={handleAllow}
            onAllowSelected={handleAllowSelected}
            onReject={handleReject}
            onOpenOptions={handleOpenOptions}
            onSelectedChange={handleSelectedChange}
        />
      </If>
    </>
  )
}

AppShell.propTypes = {
  title: PropTypes.string,
  brand: PropTypes.object,
  seo: PropTypes.object,
  children: PropTypes.node,
}

export default AppShell
