export const isExternal = (to) => {
  if (typeof window !== 'undefined' && to) {
    const currentHost = window.location.host
    const linkHostMatch = to.match(/:\/\/(.[^/]+)/)

    if (!linkHostMatch) return false
    const [, linkHost] = linkHostMatch

    return currentHost !== linkHost
  }

  return true
}
